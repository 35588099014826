import { gql } from '@apollo/client'

export const VIDEO = gql`
  query Video($id: ID!, $idType: VideoIdType, $asPreview: Boolean) {
    video(id: $id, idType: $idType, asPreview: $asPreview) {
      id
      title
      content
      date
      categories: videoCategories {
        nodes {
          name
        }
      }
    }
  }
`

export const VIDEOS = gql`
  query Videos($where: RootQueryToVideoConnectionWhereArgs) {
    videos(where: $where) {
      nodes {
        id
        title
        content
        date
      }
      pageInfo {
        offsetPagination {
          total
        }
      }
    }
    categories: videoCategories(where: { hideEmpty: true }) {
      nodes {
        id
        name
      }
    }
  }
`
